(function bookolo() {
    window.dataLayer = window.dataLayer || [];

    var bookoloDomain = "https://www.bookoloengine.com/";
    var bookoloLangs = ["en", "de", "ru", "fr", "it", "es", "cs", "sk", "hu", "ro", "pl"];
    var bookoloIsMobile = false;
    var bookoloFrameHeight;
    var bookoloEngineDiv;
    var bookoloAutoScroll = true;
    var ga4MeasurementId = null;
    var ga4ClientId = null;
    var ga4SessionId = null;

    (function (a, b) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
            bookoloIsMobile = true;
        }
    })(navigator.userAgent || navigator.vendor || window.opera, bookoloIsMobile);

    if (document.documentElement.clientWidth <= 1024) {
        bookoloIsMobile = true;
    }

    function domReady(callback) {
        document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
    }

    function random(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    function xDomainHandler(event) {
        try {
            var request = JSON.parse(event.data);
        } catch (Exception) {
            return;
        }
        var bookoloFrame = document.getElementById("bookoloFrame");

        if (request.iframeHeight !== null) {
            if (bookoloFrameHeight != request.iframeHeight) {
                bookoloFrameHeight = request.iframeHeight;
                bookoloFrame.style.height = parseInt(bookoloFrameHeight) + 'px';
            }
        }
        if (request.scrollTop) {
            if (bookoloEngineDiv.getAttribute('data-auto-scroll') === "false") {
                bookoloAutoScroll = false;
            }

            if (bookoloAutoScroll) {
                sendFramePosition();
                scrollTo(document.body, getElementOffset(bookoloFrame).top + request.scrollTop, 300);
                scrollTo(document.documentElement, getElementOffset(bookoloFrame).top + request.scrollTop, 300);
            } else {
                bookoloAutoScroll = true;
                bookoloEngineDiv.setAttribute('data-auto-scroll', 'true');
            }
        }
        if (request.backToHomepage) {
            window.location.href = window.location.protocol + "//" + window.location.host + "/";
        }

        if (request.redirect) {
            window.location.href = request.redirect;
        }
        if (request.dataLayer) {
            dataLayer.push(request.dataLayer);
        }
        if (request.gtag) {
            if (typeof gtag === 'function') {
                gtag(request.gtag.type, request.gtag.event, request.gtag.data)
            } else {
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: request.gtag.event,
                    ecommerce: request.gtag.data
                });
            }
        }
        if (request.uetq) {
            window.uetq.push(request.uetq.event, request.uetq.type, request.uetq.data);
        }
    }

    function sendFramePosition() {
        var bookoloFrame = document.getElementById("bookoloFrame");
        if (bookoloFrame) {
            var windowScrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
            var iframeOffsetTop = getElementOffset(bookoloFrame).top;
            var message = {
                do: 'windowScrollTop',
                windowScrollTop: windowScrollTop,
                iframeOffsetTop: iframeOffsetTop,
                height: window.innerHeight,
                width: window.innerWidth
            };
            bookoloFrame.contentWindow.postMessage(message, bookoloDomain);
        }
    }

    function getUrlParameter(sParam) {
        var params = Array.from(new URLSearchParams(location.search));
        var newParams = new URLSearchParams();
        for (var i = 0; i < params.length; i++) {
            var name = params[i][0];
            var value = params[i][1];
            newParams.append(name.toLowerCase(), value);
        }
        return newParams.get(sParam);
    }

    function getElementOffset(element) {
        var de = document.documentElement;
        var box = element.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        return {
            top: top,
            left: left
        };
    }

    function scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            increment = 20;

        var animateScroll = function (elapsedTime) {
            elapsedTime += increment;
            element.scrollTop = easeInOut(elapsedTime, start, change, duration);
            if (elapsedTime < duration) {
                setTimeout(function () {
                    animateScroll(elapsedTime);
                }, increment);
            }
        };

        animateScroll(0);
    }

    function easeInOut(currentTime, start, change, duration) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return change / 2 * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }

    function createIframe(url) {
        bookoloEngineDiv = document.getElementById("bookolo-engine");
        bookoloEngineDiv.innerHTML = '<iframe src="' + url + '" id="bookoloFrame" style="border:0 none; width: 100%; height: 2000px" scrolling="no" allow="payment"></iframe>';

        document.getElementById("bookoloFrame").onload = function () {
            var frameWindow = document.getElementById('bookoloFrame').contentWindow;

            // legacy GA only
            if (ga4MeasurementId === null && typeof ga !== "undefined" && typeof ga.getAll === "function") {
                ga.getAll().forEach(function (tracker) {
                    var data = {
                        do: 'setCid',
                        cid: tracker.get('clientId')
                    };
                    frameWindow.postMessage(data, bookoloDomain);
                });
            }

            window.onload = function () {
                sendFramePosition();
            };
            window.onscroll = function () {
                sendFramePosition();
            };

            if (typeof bookoloOnLoad === "function") {
                bookoloOnLoad();
            }
        };
    }

    if (!window.bookoloEventInit) {
        window.addEventListener('message', xDomainHandler, false);
        window.bookoloEventInit = true;
    }

    function getGA4data()
    {
        if (!window.dataLayer) {
            return false
        }

        if (detectParam('gaid')){
            ga4MeasurementId = detectParam('gaid')
        } else {
            window.dataLayer.forEach(function(config) {
                if (config[0] === 'config') {
                    if (typeof config[1] !== 'undefined' && config[1].startsWith('G-')) {
                        ga4MeasurementId = config[1];
                    }
                }
            })
        }

        if (ga4MeasurementId) {
            window.gtag('get', ga4MeasurementId, 'client_id', function(client_id) {
                ga4ClientId = client_id;
            })

            window.gtag('get', ga4MeasurementId, 'session_id', function(session_id) {
                ga4SessionId = session_id;
            })
        }
    }

    function detectParam(param) {
        var p = getUrlParameter(param);

        return p ? p : bookoloEngineDiv.getAttribute('data-' + param);
    }

    domReady(function () {
        bookoloEngineDiv = document.getElementById("bookolo-engine");

        if (bookoloEngineDiv) {
            if (bookoloEngineDiv.getAttribute('data-mobile-redirect') === "false") {
                bookoloIsMobile = false;
            }

            if (bookoloIsMobile) {
                bookoloEngineDiv.insertAdjacentHTML("beforeend", "<style>html { display: none }</style>")
            }

            var language = document.documentElement.lang;
            if (getUrlParameter('user_language')) {
                language = getUrlParameter('user_language');
            }
            if (bookoloEngineDiv.getAttribute('data-language')) {
                language = bookoloEngineDiv.getAttribute('data-language');
            }

            var hotelID = parseInt(bookoloEngineDiv.getAttribute('data-hotel-id'));
            var currency = bookoloEngineDiv.getAttribute('data-currency') || 'EUR';
            var page = bookoloEngineDiv.getAttribute('data-page');
            var referral = encodeURIComponent(document.location.href);

            getUrlParameter('bookolo_currency') ? currency = getUrlParameter('bookolo_currency') : null;
            getUrlParameter('bookolo_language') ? language = getUrlParameter('bookolo_language') : null;
            getUrlParameter('bookolo_page') ? page = getUrlParameter('bookolo_page') : null;

            if (detectParam('prefervoucher') || detectParam('filtervoucher')) {
                page = 'voucher';
            }

            if (bookoloLangs.indexOf(language) === -1) {
                language = 'en';
            }

            var url = bookoloDomain + "v3/book/hotel-" + hotelID + '/' +
                (page ? page : '') +
                '?lang=' + language +
                '&currency=' + currency +
                (detectParam('session') ? "&SESSID=" + detectParam('session') : '') +
                (detectParam('utm_source') ? "&utm_source=" + detectParam('utm_source') : '') +
                (detectParam('utm_medium') ? "&utm_medium=" + detectParam('utm_medium') : '') +
                (detectParam('utm_campaign') ? "&utm_campaign=" + detectParam('utm_campaign') : '') +
                (detectParam('trevor_user_id') ? "&trevor_user_id=" + detectParam('trevor_user_id') : '') +
                (detectParam('login') ? "&login=" + detectParam('login') : '') +
                (detectParam('datein') ? "&inputCheckIn=" + detectParam('datein') : '') +
                (detectParam('date_in') ? "&inputCheckIn=" + detectParam('date_in') : '') +
                (detectParam('dateinoffset') ? "&inputCheckInOffset=" + detectParam('dateinoffset') : '') +
                (detectParam('dateout') ? "&inputCheckOut=" + detectParam('dateout') : '') +
                (detectParam('date_out') ? "&inputCheckOut=" + detectParam('date_out') : '') +
                (detectParam('nights') ? "&inputNights=" + detectParam('nights') : '') +
                (detectParam('rooms') ? "&inputRoomsCount=" + detectParam('rooms') : '') +
                (detectParam('adults') ? "&inputAdultsCount=" + detectParam('adults') : '') +
                (detectParam('children') ? "&inputKidsCount=" + detectParam('children') : '') +
                (detectParam('childrenages') ? "&childrenAges=" + detectParam('childrenages') : '') +
                (detectParam('promo') ? "&inputPromo=" + detectParam('promo') : '') +
                (detectParam('promo_code') ? "&inputPromo=" + detectParam('promo_code') : '') +
                (detectParam('preferpackage') ? "&preferpackage=" + detectParam('preferpackage') : '') +
                (detectParam('preferroom') ? "&preferroom=" + detectParam('preferroom') : '') +
                (detectParam('preferrate') ? "&preferrate=" + detectParam('preferrate') : '') +
                (detectParam('prefervoucher') ? "&prefervoucher=" + detectParam('prefervoucher') : '') +
                (detectParam('preferspa') ? "&preferspa=" + detectParam('preferspa') : '') +
                (detectParam('showlogin') ? "&showlogin=" + detectParam('showlogin') : '') +
                (detectParam('showcancel') ? "&showcancel=" + detectParam('showcancel') : '') +
                (detectParam('showoffer') ? "&showoffer=" + detectParam('showoffer') : '') +
                (detectParam('showdetail') ? "&showdetail=" + detectParam('showdetail') : '') +
                (detectParam('filterroom') ? "&filterroom=" + detectParam('filterroom') : '') +
                (detectParam('filterpackage') ? "&filterpackage=" + detectParam('filterpackage') : '') +
                (detectParam('filtervoucher') ? "&filtervoucher=" + detectParam('filtervoucher') : '') +
                (detectParam('filterspa') ? "&filterspa=" + detectParam('filterspa') : '') +
                (detectParam('cpihotel') ? "&cpihotel=" + detectParam('cpihotel') : '') +
                (detectParam('theme') ? "&theme=" + detectParam('theme') : '') +
                (detectParam('cookieconsent') ? "&cookieconsent=" + detectParam('cookieconsent') : '') +
                (detectParam('balance') ? "&balance=" + detectParam('balance') : '') +
                (detectParam('gaid') ? "&gaid=" + detectParam('gaid') : '') +
                (detectParam('nopopup') ? "&nopopup=" + detectParam('nopopup') : '') +
                (detectParam('guestname') ? "&guestname=" + detectParam('guestname') : '') +
                (detectParam('guestemail') ? "&guestemail=" + detectParam('guestemail') : '') +
                (detectParam('guestphone') ? "&guestphone=" + detectParam('guestphone') : '') +
                (detectParam('guestcountry') ? "&guestcountry=" + detectParam('guestcountry') : '') +
                "&referral=" + referral;

            if (!bookoloIsMobile) {
                url += '&iframe=1';
                if (!detectParam('session')) {
                    url += '&SESSID=' + random(32);
                }
            }

            var interval = setInterval(function () {
                getGA4data();

                if (ga4MeasurementId && ga4ClientId) {
                    clearInterval(interval);
                    url += '&_gaClientId=' + ga4ClientId;
                    url += '&_gaSessionId=' + ga4SessionId;
                    bookoloIsMobile ? window.location.replace(url) : createIframe(url);
                }

                // legacy GA only
                // if (ga4MeasurementId === null && typeof ga !== "undefined" && typeof ga.getAll === "function") {
                //     clearInterval(interval);
                //     var tracker = ga.getAll()[0];
                //     var linker = new window.gaplugins.Linker(tracker);
                //     url = linker.decorate(url);
                //     bookoloIsMobile ? window.location.replace(url) : createIframe(url);
                // }
            }, 50);

            setTimeout(function () {
                clearInterval(interval);
                bookoloIsMobile ? window.location.replace(url) : createIframe(url);
            }, 500);
        }
    });
})();
